import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="boper boper-hb">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="current">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
          </div>
          <section className="hydroboots">
            <div className="section section-banner">
              <div className="wrapper">
                <div className="col-1">
                  <p>
                    * NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Water Gel
                    Nawadniający żel – test nawodnienia, 4h po aplikacji.
                  </p>
                </div>
                <div className="col-2">
                  <div className="blue">
                    <h1 className="title blue">
                      NEUTROGENA<sup>®</sup>
                      <br /> Hydro Boost<sup>®</sup>
                    </h1>
                    <span className="bold">2x</span> bardziej
                    <br /> nawodniona
                    <br /> skóra twarzy*
                  </div>
                </div>
              </div>
            </div>
            <div id="section-1" className="section section-1">
              <div className="wrapper">
                <a className="gotohydro" href="/hydroboost/#section-5">
                  <img
                    src="/themes/simple/img/hb/gotohydro.png"
                    alt="produkty Hydro boost"
                  />
                </a>
                <div className="col-1">
                  <img
                    src="/themes/simple/img/hb/packshoot.png"
                    alt="Neutrogena Hydro Boots"
                  />
                </div>
                <div className="col-2">
                  <div
                    style={{
                      "max-width": "430px"
                    }}
                  >
                    <strong>
                      Szybkie tempo życia oraz szkodliwe czynniki zewnętrzne
                      naruszają barierę ochronną skóry i powodują, że skóra
                      zaczyna tracić nawodnienie.
                    </strong>
                  </div>
                  <br />
                  <br />
                  <span className="blue">
                    NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Water Gel
                    Nawadniający żel
                  </span>
                  <br />
                  z technologią Hyaluronic Gel Matrix:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Dzięki formule z{" "}
                      <span className="blue">kwasem hialuronowym</span> gasi
                      pragnienie Twojej skóry, wzmacnia ją poprzez długotrwałe
                      nawodnienie aż do 24h. Sprawia, że skóra staje się jędrna
                      i miękka.
                    </li>
                    <li>
                      Wzmacnia zewnętrzną warstwę naskórka poprzez poprawę jej
                      nawodnienia oraz zamyka nawodnienie w skórze.
                    </li>
                    <li>
                      Chroni barierę naskórkową oraz stopniowo uwalnia składniki
                      nawilżające wtedy, kiedy skóra tego potrzebuje.{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <a href="/hydroboost/#section-2" className="btn-next" />
            </div>
            <div id="section-2" className="section section-2">
              <div className="wrapper">
                <h2 className="subtitle">
                  Rezultaty potwierdzone <br /> w badaniach
                </h2>
                <div className="stats">
                  <span className="value">86%</span>
                  <span className="desc">
                    kobiet zauważyło, że skóra staje
                    <br /> się jędrna*
                  </span>
                </div>
                <div className="stats">
                  <span className="value">91%</span>
                  <span className="desc">
                    kobiet zauważyło, że skóra <br />
                    jest miękka**
                  </span>
                </div>
                *Test Samooceny, 168 kobiet, po 1 tygodniu, aplikacja produktu
                1-2
                <br /> razy dziennie, 2016
                <br />
                ** Test Samooceny, 35 kobiet, po 4 tygodniach, aplikacja
                produktu <br />
                raz dziennie, 2014
              </div>
              <a href="/hydroboost/#section-3" className="btn-next" />
            </div>
            <div id="section-3" className="section section-3">
              <div className="wrapper">
                <div className="col1">
                  <h3 className="subtitle">
                    Technologia <br />
                    NEUTROGENA<sup>®</sup>
                    <br />
                    Hydro Boost<sup>®</sup>
                  </h3>
                  <div className="feature feature-1">
                    <span>
                      Zawiera <span className="blue">kwas hialuronowy</span>,
                      wyjątkowy składnik nawilżający, który ma zdolność do
                      zatrzymywania wody w ilości tysiąckrotnie przewyższającej
                      jego wagę.
                    </span>
                  </div>
                  <div className="feature feature-2">
                    Pomaga zamknąć nawodnienie w naskórku.
                  </div>
                  <div className="feature feature-3">
                    Utrzymuje odpowiedni poziom nawodnienia, stopniowo je
                    uwalniając, dokładnie wtedy, kiedy Twoja skóra tego
                    potrzebuje.
                  </div>
                </div>
                <div className="col2">
                  <div>
                    <iframe
                      id="hb_video"
                      width="425"
                      height="239"
                      src="https://www.youtube-nocookie.com/embed/X0MUtAQxp38?rel=0&showinfo=0"
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
              <a href="/hydroboost/#section-4" className="btn-next" />
            </div>
            <div id="section-4" className="section section-4">
              <div className="wrapper">
                <h3 className="subtitle">Nawadnianie na każdym etapie</h3>
                Poznaj kompleksowy rytuał nawadnia skóry twarzy
                <br />
                NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                <div className="hb-slider" id="hb-slider">
                  <div className="slides_container">
                    <div className="box_slide">
                      <div className="etap blue">Etap 1 – OCZYSZCZANIE</div>
                      <div className="products">
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_1_1.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                            </p>
                            Nawadniający żel do mycia twarzy
                          </div>
                          <a href="/product/66/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_1_2.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                            </p>
                            Nawadniające mleczko do twarzy
                          </div>
                          <a href="/product/67/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_1_3.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                            </p>
                            Wygładzający peeling do twarzy
                          </div>
                          <a href="/product/68/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="box_slide">
                      <div className="etap blue">Etap 2 – NAWADNIANIE</div>
                      <div className="products">
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_2_1.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                              <br />
                              Water Gel
                            </p>
                            Nawadniający żel do cery normalnej i mieszanej
                          </div>
                          <a href="/product/64/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_2_2.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                              <br />
                              Gel-cream
                            </p>
                            Nawadniający żel-krem do cery suchej
                          </div>
                          <a href="/product/65/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_2_3.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                            </p>
                            Żel-krem pod oczy przeciw oznakom zmęczenia
                          </div>
                          <a href="/product/70/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_2_4.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                            </p>
                            Nawadniający booster. Serum do twarzy
                          </div>
                          <a href="/product/81/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="box_slide">
                      <div className="etap blue">Etap 3 – REGENERACJA</div>
                      <div className="products">
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_3_1.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                            </p>
                            Nawadniający krem-maska na noc
                          </div>
                          <a href="/product/71/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="box_slide">
                      <div className="etap blue">Etap 4 – OCHRONA</div>
                      <div className="products">
                        <div className="prod_item">
                          <div className="photo">
                            <img src="/themes/simple/img/hb/prod_4_1.png" alt />
                          </div>
                          <div className="desc">
                            <p className="blue">
                              NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                              <br />
                              City shield{" "}
                            </p>
                            Nawadniający krem do twarzy SPF 25
                          </div>
                          <a href="/product/69/" className="btn">
                            ZOBACZ
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="/hydroboost/#section-5" className="btn-next" />
            </div>
            <div id="section-5" className="section section-5">
              <div className="wrapper">
                <h2 className="title">
                  Skóra ciała także wymaga
                  <br /> odpowiedniego nawodnienia
                </h2>
                Odkryj linię produktów{" "}
                <span className="blue">
                  NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                </span>{" "}
                do ciała oraz dłoni
                <br />
                i wprowadź do swojej codziennej pielęgnacji rytuał nawadniania
                skóry, <br />
                dzięki formułom z{" "}
                <span className="blue">kwasem hialuronowym</span>.
              </div>
              <div className="products">
                <div className="wrapper">
                  <div className="prod_item">
                    <span className="blue">
                      NEUTROGENA<sup>®</sup> <br />
                      HydroBoost<sup>®</sup>
                    </span>
                    <br />
                    Ekspresowy
                    <br />
                    spray do ciała
                    <a href="/product/78/" className="btn">
                      ZOBACZ
                    </a>
                  </div>
                  <div className="prod_item">
                    <span className="blue">
                      NEUTROGENA<sup>®</sup> <br />
                      HydroBoost<sup>®</sup>
                    </span>
                    <br />
                    Żelowy Balsam
                    <br />
                    do ciała
                    <a href="/product/77/" className="btn">
                      ZOBACZ
                    </a>
                  </div>
                  <div className="prod_item">
                    <span className="blue">
                      NEUTROGENA<sup>®</sup> <br />
                      HydroBoost<sup>®</sup>
                    </span>
                    <br />
                    Żelowy Krem
                    <br />
                    do rąk
                    <a href="/product/80/" className="btn">
                      ZOBACZ
                    </a>
                  </div>
                  <div className="prod_item">
                    <span className="blue">
                      NEUTROGENA<sup>®</sup> <br />
                      HydroBoost<sup>®</sup>
                    </span>
                    <br />
                    Aksamitny
                    <br />
                    mus do ciała
                    <a href="/product/79/" className="btn">
                      ZOBACZ
                    </a>
                  </div>
                </div>
              </div>
              <a href="/hydroboost/#section-7" className="btn-next" />
            </div>
            <div id="section-7" className="section section-7">
              <div className="wrapper">
                <h3 className="subtitle">Pytania i odpowiedzi</h3>
                <div className="faq">
                  <div className="faq__item">
                    <div className="faq__item__photo">
                      <img
                        src="/themes/simple/img/hb/faq/f-1.png"
                        alt="Jakie czynniki powodują odwodnienie skóry?"
                      />
                    </div>
                    <div className="faq__item__content">
                      <h3 className="faq__item__name">
                        Jakie czynniki powodują odwodnienie skóry?
                      </h3>
                      <div className="faq__item__short">
                        Zanieczyszczenia, agresywne czynniki zewnętrzne i
                        intensywny styl życia to główne czynniki, które
                        powodują, że skóra traci...
                        <span className="btn__more" />
                      </div>
                      <div className="faq__item__full">
                        Zanieczyszczenia, agresywne czynniki zewnętrzne i
                        intensywny styl życia to główne czynniki, które
                        powodują, że skóra traci nawodnienie. W efekcie staje
                        się sucha i pozbawiona blasku i narażona na przedwczesne
                        powstawanie zmarszczek
                        <span className="btn__less" />
                      </div>
                    </div>
                  </div>
                  <div className="faq__item">
                    <div className="faq__item__photo">
                      <img
                        src="/themes/simple/img/hb/faq/f-2.png"
                        alt="Czym jest NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>?"
                      />
                    </div>
                    <div className="faq__item__content">
                      <h3 className="faq__item__name">
                        Czym jest NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>
                        ?{" "}
                      </h3>
                      <div className="faq__item__short">
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> to
                        pierwsza linia kosmetyków do kompleksowej pielęgnacji
                        twarzy marki Neutrogena<sup>®</sup>, które
                        intensywnie...
                        <span className="btn__more" />
                      </div>
                      <div className="faq__item__full">
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> to
                        pierwsza linia kosmetyków do kompleksowej pielęgnacji
                        twarzy marki Neutrogena<sup>®</sup>, które intensywnie
                        nawadniają skórę. Formuły z kwasem hialuronowym
                        zatrzymują wodę w naskórku i sprawiają, że skóra
                        pozostaje nawilżona i miękka w dotyku. Produkty z linii
                        Hydro Boost<sup>®</sup> zostały stworzone we współpracy
                        z dermatologami.
                        <span className="btn__less" />
                      </div>
                    </div>
                  </div>
                  <div className="faq__item">
                    <div className="faq__item__photo">
                      <img
                        src="/themes/simple/img/hb/faq/f-3.png"
                        alt="Dla kogo przeznaczone są produkty NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>?"
                      />
                    </div>
                    <div className="faq__item__content">
                      <h3 className="faq__item__name">
                        Dla kogo przeznaczone są produkty NEUTROGENA<sup>®</sup>{" "}
                        Hydro Boost<sup>®</sup>?
                      </h3>
                      <div className="faq__item__short">
                        Linia NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        przeznaczona jest dla osób, które zauważają, że ich
                        skóra zmienia się wraz z upływem czasu, wykazując...
                        <span className="btn__more" />
                      </div>
                      <div className="faq__item__full">
                        Linia NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        przeznaczona jest dla osób, które zauważają, że ich
                        skóra zmienia się wraz z upływem czasu, wykazując
                        pierwsze oznaki odwodnienia i stopniowo tracąc jędrność,
                        elastyczność i naturalną sprężystość. O nawilżenie skóry
                        należy dbać od najmłodszych lat. NEUTROGENA<sup>®</sup>{" "}
                        Hydro Boost<sup>®</sup> mogą używać już 20-latki.
                        Zaczynając odpowiednią pielęgnację nawilżającą tak
                        wcześnie, zwiększamy swoje szanse na utrzymanie zdrowo
                        wyglądającej skóry przez długi czas.
                        <span className="btn__less" />
                      </div>
                    </div>
                  </div>
                  <div className="faq__item">
                    <div className="faq__item__photo">
                      <img
                        src="/themes/simple/img/hb/faq/f-4.png"
                        alt="Co wyróżnia linię NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> na tle innych produktów"
                      />
                    </div>
                    <div className="faq__item__content">
                      <h3 className="faq__item__name">
                        Co wyróżnia linię NEUTROGENA<sup>®</sup> Hydro Boost
                        <sup>®</sup> na tle innych produktów nawilżających
                        opartych na wodzie, dostępnych obecnie na rynku?{" "}
                      </h3>
                      <div className="faq__item__short">
                        Technologie zastosowane w produktach z linii NEUTROGENA
                        <sup>®</sup>...
                        <span className="btn__more" />
                      </div>
                      <div className="faq__item__full">
                        Technologie zastosowane w produktach z linii NEUTROGENA
                        <sup>®</sup> Hydro Boost<sup>®</sup> są wyjątkowe
                        spośród dostępnych kosmetyków do pielęgnacji twarzy.
                        Hyaluronic Gel Matrix<sup>®</sup> zawarta w produktach
                        pielęgnacyjnych pomaga tworzyć rezerwuar wody w skórze i
                        przez cały dzień stopniowo uwalniać wodę do górnych
                        warstw naskórka wtedy, gdy skóra tego potrzebuje. <br />
                        Z kolei technologia BarrierCare<sup>®</sup> zatosowana w
                        produktach oczyszczających nie narusza bariery ochronnej
                        skóry, a formuły wzbogacone o kwas hialuronowy i inne
                        składniki nawilżające poprawiają nawodnienie skóry.
                        <span className="btn__less" />
                      </div>
                    </div>
                  </div>
                  <div className="faq__item">
                    <div className="faq__item__photo">
                      <img
                        src="/themes/simple/img/hb/faq/f-5.png"
                        alt="Czym różni się NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Water Gel Nawadniający żel od Neutrogena<sup>®</sup> Hydro Boost<sup>®</sup> Gel-cream Nawadniający żel-krem?"
                      />
                    </div>
                    <div className="faq__item__content">
                      <h3 className="faq__item__name">
                        Czym różni się NEUTROGENA<sup>®</sup> Hydro Boost
                        <sup>®</sup> Water Gel Nawadniający żel od Neutrogena
                        <sup>®</sup> Hydro Boost<sup>®</sup> Gel-cream
                        Nawadniający żel-krem?{" "}
                      </h3>
                      <div className="faq__item__short">
                        Formuły te opracowano z myślą o potrzebach różnych typów
                        skóry...
                        <span className="btn__more" />
                      </div>
                      <div className="faq__item__full">
                        Formuły te opracowano z myślą o potrzebach różnych typów
                        skóry. NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Water Gel Nawadniający żel jest przeznaczony do skóry
                        tłustej oraz skóry normalnej i mieszanej. Jego formuła
                        ma przyjemny zapach oraz lekką konsystencję żelu.
                        Neutrogena<sup>®</sup> Hydro Boost<sup>®</sup> Gel-cream
                        Nawadniający żel-krem idealne sprawdza się w przypadku
                        skóry suchej. To produkt bezzapachowy o nieco bogatszej
                        konsystencji.
                        <span className="btn__less" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="/hydroboost/#section-6" className="btn-next" />
            </div>
            <div id="section-6" className="section section-6">
              <div className="wrapper">
                <p
                  style={{
                    "font-size": "36px",
                    padding: "0 0 25px"
                  }}
                >
                  Stwórz swój rytuał nawadniania skóry
                </p>
                Przestań nawilżać, zacznij nawadniać. Zobacz porady eksperta{" "}
                <br />i stwórz kompleksowy rytuał nawadniania twarzy i ciała.
                <br />
                <br />
                <div className="avatar" />
                <br />
                <br />
                <p
                  style={{
                    "font-size": "14px",
                    "line-height": "16px",
                    "font-style": "italic"
                  }}
                >
                  <strong>dr Dominika Kwaśniak</strong>
                  <br />
                  Lekarz Medycyny Estetycznej
                </p>
                <br />
                <br />
                <a
                  href="/bad-piekna/pielegnacja-twarzy/stworz-swoj-rytual-nawadniania-skory/"
                  className="btn"
                >
                  Dowiedz się więcej
                </a>
              </div>
            </div>
          </section>
          <script
            type="text/javascript"
            src="/site/themes/simple/js/slides.min.jquery.js"
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\nvar activescroll = true;\n$( '.hydroboots a[href*=\\#]' ).on('click', function(event){    \nevent.preventDefault();\nif( !$(this).data('redirect') ) {\nactivescroll = false;\nvar regulation = 0;\nif( $(this.hash).attr('id') == 'top' )\n{\nregulation = 0;\n}\n$('html,body').animate({scrollTop:$(this.hash).offset().top - regulation }, 500, function(){\nactivescroll = true;\n$( '#menu' ).removeClass( 'open' );\n});\n} else {\ndocument.location.href=$(this).attr('href');\n}\n});\n$('#hb-slider').slides({\npreload: true,\npreloadImage: 'img/loading.gif',\neffect: 'slide',\ncrossfade: true,\nslideSpeed: 350,\nfadeSpeed: 500,\ngenerateNextPrev: true,\ngeneratePagination: false\n});\n$('.movie-btn').on('click', function(){\n$( '.movie_layer' ).addClass( 'open');\n});\n$('.movie_layer .close').on('click', function(){\n$( '.movie_layer' ).removeClass( 'open');\n});\nvar frame = document.getElementById(\"hb_video\");\nframe.contentWindow.postMessage(\n'{\"event\":\"command\",\"func\":\"pauseVideo\",\"args\":\"\"}',\n'*');\n"
            }}
          />
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
